import * as React from "react"
import axios from "axios"
import Layout from "../components/ui/layout"
import Seo from "../components/ui/seo"
import Box from "@material-ui/core/Box"
import CircularProgress from "@material-ui/core/CircularProgress"
import CartPanel from "../components/cart/CartPanel"
import { setStoreSettings } from "../contexts/actions"
import isClosed from "../helperFunc/isClosed"

import {
  UserContext,
  FeedbackContext,
  CartContext,
  OrderOptionsContext,
  StoreSettingsContext,
} from "../contexts"

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  outBox: {
    width: "100%",
    minHeight: "calc(100svh  - 66px)",
    position: "relative",
    overflowY: "scroll",
  },

  inBox: {
    width: "80%",
    textAlign: "center",
    position: "absolute",
    left: "50%",
    top: "50%",
    msTransform: "translate(-50%, -50%)",
    transform: "translate(-50%, -50%)",
  },
}))

const Cart = ({ location }) => {
  const { user, dispatchUser, defaultUser } = React.useContext(UserContext)
  const { feedback, dispatchFeedback } = React.useContext(FeedbackContext)
  const { cart, dispatchCart } = React.useContext(CartContext)
  const [loading, setLoading] = React.useState(true)

  const { orderOptions, dispatchOrderOptions } =
    React.useContext(OrderOptionsContext)

  const { dispatchStoreSettings } = React.useContext(StoreSettingsContext)
  const classes = useStyles()

  React.useEffect(() => {
    axios
      .get(
        process.env.GATSBY_STRAPI_URL + "/api/store-setting/get-store-setting"
      )
      .then(response => {
        let timeDiff = Date.now() - response.data.dateNow
        let {
          isResOutOfHours,
          minutesToClose,
          surcharge,
          orderWindows,
          tableList,
        } = isClosed(response.data, timeDiff)
        let dataToSet = {
          ...response.data,
          isResOutOfHours: isResOutOfHours,
          minutesToClose: minutesToClose,
          surcharge: surcharge,
          timeDiff: timeDiff,
          orderWindows: orderWindows,
          tableList: tableList,
        }
        dispatchStoreSettings(setStoreSettings(dataToSet))
        setLoading(false)
      })
      .catch(error => {
        console.error(error)
        dispatchStoreSettings(setStoreSettings({ error: true }))
        setLoading(false)
      })
  }, [dispatchStoreSettings])
  //console.log(new Date(Date.now() + time.time).toISOString())

  return (
    <Layout footerOne={false} footerTwo={false}>
      <Seo title="Cart" description="Order details" />
      {loading ? (
        <Box classes={{ root: classes.outBox }}>
          <Box classes={{ root: classes.inBox }}>
            <CircularProgress color="primary" />
          </Box>
        </Box>
      ) : (
        <CartPanel
          user={user}
          dispatchUser={dispatchUser}
          defaultUser={defaultUser}
          feedback={feedback}
          dispatchFeedback={dispatchFeedback}
          cart={cart}
          dispatchCart={dispatchCart}
          orderOptions={orderOptions}
          dispatchOrderOptions={dispatchOrderOptions}
          location={location}
        />
      )}
    </Layout>
  )
}

export default Cart
