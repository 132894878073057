import * as React from "react"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import Chip from "@material-ui/core/Chip"

import { makeStyles } from "@material-ui/core/styles"
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined"

const useStyles = makeStyles(theme => ({
  outBox: {
    width: "100%",
    paddingTop: "35svh",
    paddingBottom: "20svh",
    position: "relative",
  },

  inBox: {
    width: "80%",
    textAlign: "center",
    position: "absolute",
    left: "50%",
    top: "50%",
    msTransform: "translate(-50%, -50%)",
    transform: "translate(-50%, -50%)",
  },
  icon: {
    fontSize: "4rem !important",
  },

  orderLabel: {
    marginTop: "0.5rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  chip: {
    marginLeft: "0.5rem",
  },
  chipText: {
    fontSize: "1.0rem",
    fontWeight: 700,
  },
  confirmText: {
    fontSize: "0.9rem",
    marginTop: "1.5rem",
  },
}))

const CheckoutDone = ({ order, user }) => {
  const classes = useStyles()
  return (
    <Box classes={{ root: classes.outBox }}>
      <Box classes={{ root: classes.inBox }}>
        <CheckCircleOutlineOutlinedIcon classes={{ root: classes.icon }} />
        <Typography variant="h5">{`Thank you, ${user.userDisplayName}!`}</Typography>
        <Box classes={{ root: classes.orderLabel }}>
          <Typography variant="body1">{`Order number `}</Typography>
          <Chip
            classes={{ root: classes.chip }}
            label={
              <Typography
                variant="body1"
                classes={{ root: classes.chipText }}
              >{`${order.id}`}</Typography>
            }
          ></Chip>
        </Box>
        <Typography classes={{ root: classes.confirmText }} variant="body1">
          {order.orderType === "takeAway"
            ? `We'll send you a text message once your order is ready for pick up!`
            : order.orderType === "dineIn"
            ? `Sit tight and we will bring your order to table ${order.orderTableNumber} shortly!`
            : ""}
        </Typography>
      </Box>
    </Box>
  )
}

export default CheckoutDone
